import React from "react"
import Seo from "../components/seo"
import Container from "@mui/material/Container"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import FlexRow from "components/containers/flexRow"
import FlexColumn from "components/containers/flexColumn"
import { EnquiriesButton } from "components/buttons/EnquiriesButton"
import Box from "@mui/system/Box"
import ContactForm from "components/ContactForm"

/*----------------------------------------------------------------
*******************************

About Us/Me page for Slick Blue Dragon

*******************************
----------------------------------------------------------------*/

const About = () => {
  return (
    <>
      <Container maxWidth="false" sx={{ maxWidth: "90%", margin: "0 auto" }}>
        <Seo title="About" />
        <FlexRow sx={{ paddingTop: "2em", paddingBottom: "1.7em" }}>
          <FlexColumn sx={{ alignItems: "center" }}>
            <Typography
              variant="h1"
              sx={{
                "&.MuiTypography-h1": {
                  fontSize: "2.25rem",
                  fontWeight: "500",
                  paddingTop: "0.5em",
                  paddingBottom: "0.5em",
                },
              }}
            >
              About Slick Blue Dragon
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { md: "3rem" }, paddingBottom: "1.5em" }}
            >
              Slick Blue Dragon as a brand was founded to provide new
              opportunities for organisations to achieve their goals and focus
              on what they need for their presence on the internet right now and
              in the future.
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { md: "3rem" }, paddingBottom: "1.5em" }}
            >
              As the founder of this small business, my passions are to use web
              technologies to enhance the health sector and creative
              professionals. This will enable qualified health professionals to
              be more productive and support service users. For creative
              professionals to have the option to market their work and improve
              their ability to do so online.
            </Typography>
          </FlexColumn>
        </FlexRow>
      </Container>
      <Box
        sx={{
          backgroundColor: "primary.offWhite",
          paddingTop: "2em",
          paddingBottom: "2em",
          borderColor: "primary.accentLight",
          borderTopStyle: "inset",
          borderBottomStyle: "inset",
          borderTopWidth: "0.75em",
          borderBottomWidth: "0.75em",
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            maxWidth: "90%",
            width: "100%",
            margin: "0 auto",
            backgroundColor: "primary.offWhite",
          }}
        >
          <FlexRow>
            <FlexColumn>
              <Typography
                variant="body1"
                sx={{ fontSize: { md: "3rem" }, paddingBottom: "1.5em" }}
              >
                Based in Peterborough UK where I live my passion for the local
                area means I aim to develop valuable partnerships locally in the
                first instance as well as across the UK.{" "}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: { md: "3rem" } }}>
                If that sounds like you or know someone who may be interested in
                being a client please do get in contact with me below.
              </Typography>
            </FlexColumn>
          </FlexRow>
        </Container>
      </Box>
      <Box sx={{ paddingTop: "2.15em", paddingBottom: "0.75em" }}>
        <Container
          maxWidth="false"
          sx={{
            maxWidth: "700px",
            width: { xs: "95%", md: "80%" },
            padding: "1em 5%",
            marginTop: "0.5em",
            backgroundColor: "primary.offWhite",
            border: "0.25em solid black",
            borderRadius: "0.5em",
            boxShadow: "0.5em 0.5em 0.75em",
          }}
        >
          <FlexRow sx={{ justifyContent: "center" }}>
            <ContactForm />
          </FlexRow>
        </Container>
      </Box>
      <Box>
        <Container maxWidth="false" sx={{ maxWidth: "700px", width: "100%" }}>
          <FlexRow sx={{ justifyContent: "center" }}>
            <Link underline="none" sx={{}} href="/contact">
              <EnquiriesButton />
            </Link>
          </FlexRow>
        </Container>
      </Box>
    </>
  )
}

export default About

export function Head() {
  return (
    <Seo
      title="Freelance Web Development Based in Peterborough UK"
      description="Slick Blue Dragon as a brand originated from a personal passion for technology."
    />
  )
}
